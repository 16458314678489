import React from 'react'
import { Link } from 'gatsby'
import {Container, Row, Col} from 'react-bootstrap'
import Menu from '../menu'


const Header = () => (
  <div className='menu'>
      <Container>
        <Row className='align-items-center'>
          <Col sm={6} xs={3}>
            
            <Link to='/'>
              <img className='d-none d-md-block' src='https://anzac-production.s3.us-west-2.amazonaws.com/logo_8a1762bf07.png' alt='anzac day commemoration committee'/>
              <img className='d-block d-md-none' src='https://anzac-production.s3.us-west-2.amazonaws.com/logo_small_e23322772b.png' alt='anzac day commemoration committee'/>
            </Link>
          </Col>
          <Col sm={6} xs={9}>
            <Menu/>
          </Col>
        </Row>
      </Container>
    </div>
)

export default Header