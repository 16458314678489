import React from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import * as styl from "./index.module.css";

console.log("style", styl);
const Sidebar = () => (
  <ul className="m-0 d-flex flex-wrap align-items-start" id={styl.boxSide}>
    <li className="bg-dark-brown">
      <Link to="/current-catalogue" title="online shop">
        <LazyLoadImage
          effect="blur"
          src="https://anzac-production.s3.us-west-2.amazonaws.com/online_shop_icon_78fab1371e.png"
          height="53"
          alt="online shop"
        />
        Online Shop Including Catalogue
      </Link>
    </li>
    <li className="bg-light-brown">
      <Link to="/about-us" title="about us">
        <LazyLoadImage
          effect="blur"
          src="https://anzac-production.s3.us-west-2.amazonaws.com/about_ADDC_5ae9eb5820.png"
          height="53"
          alt="about ADCC"
        />
        About the ADCC
      </Link>
    </li>
    <li className="bg-light-brown">
      <Link to="/adcc-merchandise-for-schools" title="adcc merchandise">
        <LazyLoadImage
          effect="blur"
          src="https://anzac-production.s3.us-west-2.amazonaws.com/school_9138c52d4f.png"
          height="53"
          alt="school orders"
        />
        School Orders and Information
      </Link>
    </li>
    <li className="bg-dark-brown">
      <Link to="/commemoration-activities" title="commemoration activities">
        <LazyLoadImage
          effect="blur"
          src="https://anzac-production.s3.us-west-2.amazonaws.com/commemoration_d54215ee33.png"
          height="53"
          alt="graves"
        />
        Graves &amp; Memorials Grants Program
      </Link>
    </li>
    <li className="bg-dark-brown">
      <Link to="/service-activities" title="services activities">
        <LazyLoadImage
          effect="blur"
          src="https://anzac-production.s3.us-west-2.amazonaws.com/services_43f8ef3579.png"
          height="53"
          alt="ANZAC Services"
        />
        ANZAC Service Schedule and Guides
      </Link>
    </li>
    <li className="bg-light-brown">
      <Link to="/the-spirit-of-anzac" title="spirit of ANZAC">
        <LazyLoadImage
          effect="blur"
          src="https://anzac-production.s3.us-west-2.amazonaws.com/spirit_ea75492155.png"
          height="53"
          alt="ANZAC traditions"
        />
        ANZAC Traditions Facts &amp; Folklore
      </Link>
    </li>
  </ul>
);

export default Sidebar;
