import React from "react";
import { Container, Row, Col, Media } from "react-bootstrap";
// import Slide_1600w from '../../images/slide4-1600w.jpg'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Match } from "@reach/router";
import * as styl from "./index.module.css";

const Hero = ({ children }) => (
  <Match path="/">
    {props =>
      props.match ? (
        <div className="hero">
          <Media>
            <LazyLoadImage
              effect="blur"
              className="w-100 mb-0"
              src="https://anzac-production.s3.us-west-2.amazonaws.com/slide_414_6d0223be49.jpg"
              srcSet="https://anzac-production.s3.us-west-2.amazonaws.com/slide_414_6d0223be49.jpg 414w,
                      https://anzac-production.s3.us-west-2.amazonaws.com/slide_568_dfbb168242.jpg 568w,
                      https://anzac-production.s3.us-west-2.amazonaws.com/slide_667_f133982700.jpg 667w,
                      https://anzac-production.s3.us-west-2.amazonaws.com/slide_767_5af7543e45.jpg 767w,
                      https://anzac-production.s3.us-west-2.amazonaws.com/slide_823_3f3412407b.jpg 823w,
                      https://anzac-production.s3.us-west-2.amazonaws.com/slide_1366_b252aee1f2.jpg 1366w,
                      https://anzac-production.s3.us-west-2.amazonaws.com/slide_1600_11c791a33e.jpg 1600w"
              alt="ANZAC banner"
            />
          </Media>
        </div>
      ) : (
        <div className={styl.heroMain}>
          <Container>
            <Row>
              <Col sm={8} md={7}>
                <h1>{children}</h1>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
  </Match>
);
export default Hero;
